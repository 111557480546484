import moment, { Moment } from "moment-timezone"

class ManageDate {
  static now(addDays = 0) {
    return moment(new Date(), "YYYY-MM-DDTHH:mm").add(addDays, "days")
  }

  static nowAddDays(addDays = 0) {
    return moment(new Date(), "YYYY-MM-DDTHH:mm").add(addDays, "days")
  }

  static addDays(date?: string, days = 0, format = "YYYY-MM-DDTHH:mm") {
    const managerDate = moment(date ? date : new Date(), "YYYY-MM-DDTHH:mm")
    return managerDate.add(days, "days").format(format)
  }

  static subtractDays(date?: string, days = 0) {
    const managerDate = moment(date, "YYYY-MM-DDTHH:mm")

    return managerDate.subtract(days, "days").format("YYYY-MM-DDTHH:mm:ss")
  }

  static isBetween(start: string, end: string, date: string): boolean {
    const startDate = moment(start)
    const endDate = moment(end)
    const targetDate = moment(date)

    if (!startDate.isValid() || !endDate.isValid() || !targetDate.isValid()) {
      return false
    }

    return targetDate.isBetween(startDate, endDate, "minutes", "[]")
  }

  static changeYear(date: string, year: string) {
    const mDate = moment(date)

    if (mDate.isValid()) {
      const newDate = `${year}-${mDate.format("MM")}-${mDate.format("DD")}`
      return moment(newDate, "YYYY-MM-DD")
    }

    return null
  }

  static mountDate(date?: string) {
    const mDate = moment(date)

    if (mDate.isValid()) {
      return moment(date, "YYYY-MM-DDTHH:mm:ss")
    }

    return ManageDate.now()
  }

  static listOfDaysBetweenDates(startDate: string, endDate: string): string[] {
    if (!startDate || !endDate) {
      return []
    }

    if (startDate === endDate) {
      return [startDate]
    }

    const listOfDays: string[] = []
    let currentDate = moment(startDate)
    const lastDate = moment(endDate)

    if (!currentDate.isValid() || !lastDate.isValid()) {
      return []
    }

    const formatLastDate = lastDate.format("YYYY-MM-DD")

    while (true) {
      const current = currentDate.format("YYYY-MM-DD")
      listOfDays.push(current)

      if (current === formatLastDate) break

      currentDate = currentDate.add(1, "days")
    }

    return listOfDays
  }

  static formatTzUTCBrazil(
    date?: string,
    timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone ||
      "America/Sao_Paulo",
  ) {
    const mDate = moment.utc(date).tz(timezone)
    return mDate.format("DD/MM/YYYY HH:mm")
  }

  static formatDate(date?: string) {
    const mDate = moment(date)
    return mDate.format("YYYY-MM-DD")
  }

  static formatBrazil(date?: string, format: string = "DD/MM/YYYY") {
    const mDate = moment(date, "YYYY-MM-DDTHH:mm")
    return mDate.format(format)
  }

  static formatBrazilHour(date?: string) {
    const mDate = moment(date, "YYYY-MM-DDTHH:mm")
    return mDate.format("DD/MM/YYYY HH:mm")
  }

  static formatDatabaseDate(date?: Moment) {
    return date?.format("YYYY-MM-DD")
  }

  static transformBrazilWithHourToMoment(date?: string) {
    return moment(date, "DD/MM/YYYY HH:mm")
  }

  static friendlyDayFormat(date?: string) {
    const mDate = moment(date)
    return mDate.format("ddd")
  }

  static friendlyDateTimeFormat(date?: string) {
    const mDate = moment(date, "YYYY-MM-DDTHH:mm")
    return `${mDate.format("DD/MM/YYYY")} às ${mDate.format("HH:mm")}`
  }

  static friendlyFormat(date?: string) {
    const mDate = moment(date, "YYYY-MM-DDTHH:mm")
    const year = mDate.format("YYYY")
    const day = mDate.format("DD")
    const month = mDate.format("MMMM")
    const time = mDate.format("HH:mm")

    return `${day} de ${month} de ${year} às ${time}`
  }

  static friendlyFormatCreate(date?: string) {
    // 02 de junho às 17:13
    const mDate = moment(date, "YYYY-MM-DDTHH:mm")
    const day = mDate.format("DD")
    const month = mDate.format("MMM")
    const year = mDate.format("YYYY")

    return `${day} de ${month} de ${year}`
  }

  static formatHourAndMinute(date?: string): string {
    const momentDate = date
      ? moment(date, "YYYY-MM-DDTHH:mm")
      : ManageDate.now()

    const hour = momentDate.format("HH")
    const minute = momentDate.format("mm")

    return `${hour}:${minute}`
  }

  static formatRentDone(start?: string, end?: string) {
    const getStartDate = moment(start, "YYYY-MM-DDTHH:mm")
    const getEndDate = moment(end, "YYYY-MM-DDTHH:mm")

    const startDayMonth = getStartDate.format("dddd, DD MMMM")
    const endDayMonth = getEndDate.format("dddd, DD MMMM")

    const startTime = getStartDate.format("HH:mm")
    const endTime = getEndDate.format("HH:mm")

    return { endDayMonth, endTime, startDayMonth, startTime }
  }
}

export default ManageDate
