import React, { useState } from "react"

import { Select as AntSelect, Drawer } from "antd"

import useDeviceDetection from "@/hooks/useDeviceDetection"

import "./style.css"

const { OptGroup, Option } = AntSelect

type OptionValue = { value: string | number; text: string | number }

export type SelectOption = OptionValue & {
  group?: string
  options?: OptionValue[]
}

type SelectProps = {
  mobileTitle?: string
  value?: string
  selectClassName?: string
  defaultValue?: string
  allowClear?: boolean
  bordered?: boolean
  disabled?: boolean
  loading?: boolean
  onSelect?: (value: any, option: any) => void
  onSelectMobile?: (value: any, option: any) => void
  onClear?: () => void
  placeholder?: string
  showSearch?: boolean
  option?: SelectOption[]
}

function Select({
  mobileTitle,
  onSelect,
  onSelectMobile,
  selectClassName,
  ...props
}: SelectProps) {
  const [open, setOpen] = useState(false)
  const device = useDeviceDetection()

  const onOpenDrawerTimeMobile = () => {
    setOpen(true)

    setTimeout(() => {
      const value = props.value || props.defaultValue

      const elements = document.querySelectorAll(`div[data-id="${value}"]`)

      elements.forEach((element) => {
        element?.scrollIntoView({ behavior: "smooth", block: "center" })
      })
    }, 300)
  }

  const onClose = () => {
    setOpen(false)
  }

  const onSelectTimeMobile = (option: OptionValue, value: string | number) => {
    setOpen(false)
    onSelect?.(option, value)
    onSelectMobile?.(option, value)
  }

  return (
    <>
      {device === "mobile" && (
        <Drawer
          closable
          onClose={onClose}
          open={open}
          placement="bottom"
          size="large"
          title={mobileTitle}
        >
          <div>
            {props?.option?.map((opt) => {
              const valueSelected = props.value || props.defaultValue
              const isSelected = opt.value === valueSelected
              return (
                <div
                  className={`text-center py-2 ${isSelected ? "bg-slate-100" : ""}`}
                  data-id={opt.value}
                  key={opt.value}
                  onClick={() => onSelectTimeMobile(opt, opt.value)}
                >
                  <span
                    className={`text-3xl hover:text-slate-500 ${isSelected ? "font-bold" : ""}`}
                  >
                    {opt.text}
                  </span>
                </div>
              )
            })}
          </div>
        </Drawer>
      )}
      <AntSelect
        {...props}
        allowClear={props.allowClear || false}
        bordered={props.bordered || true}
        className={`${selectClassName}${device === "mobile" ? " select-disabled-for-mobile" : ""}`}
        disabled={device === "mobile" ? true : props.disabled || false}
        loading={props.loading || false}
        onClear={props.onClear}
        onClick={onOpenDrawerTimeMobile}
        onSelect={onSelect}
        optionFilterProp="children"
        placeholder={props.placeholder}
        showSearch={props.showSearch || false}
        size="large"
      >
        {props?.option?.map((opt) => {
          if (opt.group) {
            return (
              <OptGroup key={opt.group} label={opt?.group}>
                {opt.options?.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.text}
                  </Option>
                ))}
              </OptGroup>
            )
          } else {
            return (
              <Option key={opt.value} value={opt.value}>
                {opt.text}
              </Option>
            )
          }
        })}
      </AntSelect>
    </>
  )
}

export default Select
