import React from "react"

import { Form } from "antd"
import locale from "antd/es/date-picker/locale/pt_BR"
import Text from "components/atom/text"
import { Moment } from "moment"

import DatePicker from "../datepicker"

interface FormDatePickerProps {
  autoComplete?: string
  className?: string
  disabled?: boolean
  disabledDate?: (date: Moment) => boolean
  hasFeedback?: boolean
  hidden?: boolean
  inputReadOnly?: boolean
  label?: string
  mobileOptions?: any
  name: string
  onSelect?: (date: Moment) => void
  onSelectTime?: (date: Moment) => void
  required?: boolean
}

const FormDatePicker: React.FC<FormDatePickerProps> = (props) => {
  const form = Form.useFormInstance()
  const rules = []

  const valueDatePicker = Form.useWatch(props.name, form)

  if (props?.required) {
    rules.push({ message: "Este campo não pode ser vazio.", required: true })
  }

  const onSelectMobile = (date: Moment) => {
    form.setFieldValue(props.name, date)
    props.onSelect?.(date)
  }

  return (
    <div className={props.hidden ? "_display-none" : ""}>
      <Text className="_text-2 _font">
        {props.label}
        {props.required && props.label && " *"}
      </Text>

      <Form.Item
        className={props.className}
        hasFeedback={props.hasFeedback || false}
        name={props.name}
        rules={rules}
      >
        <DatePicker
          autoComplete={props.autoComplete || undefined}
          currentDate={valueDatePicker}
          disabled={props.disabled || false}
          disabledDate={props?.disabledDate}
          format="DD/MM/YYYY"
          inputReadOnly={props?.inputReadOnly}
          locale={locale}
          mobileOptions={props.mobileOptions}
          onSelect={props.onSelect}
          onSelectMobile={onSelectMobile}
          size="large"
          style={{
            width: "100%",
          }}
        />
      </Form.Item>
    </div>
  )
}

export default FormDatePicker
