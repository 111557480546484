import React from "react"

import { CloseOutlined, CheckOutlined } from "@ant-design/icons"
import { Form, Switch } from "antd"
import Text from "components/atom/text"

interface FormSwitchProps {
  checkedChildren?: React.ReactNode
  className?: string
  defaultChecked?: boolean
  disabled?: boolean
  extra?: string
  label?: string
  name: string
  onChange?: any
  required?: boolean
  unCheckedChildren?: React.ReactNode
}

const FormSwitch: React.FC<FormSwitchProps> = (props) => {
  const rules = []

  if (props?.required) {
    rules.push({ message: "Este campo não pode ser vazio.", required: true })
  }

  const onChange = (checked: boolean) => {
    props.onChange?.(checked)
  }

  return (
    <div>
      {props.label && (
        <Text className="_text-2">
          {props.label}
          {props.required && " *"}
        </Text>
      )}

      <Form.Item
        className={props.className}
        extra={props.extra || null}
        name={props.name}
        rules={rules}
        valuePropName="checked"
      >
        <Switch
          checkedChildren={
            props?.checkedChildren ? props.checkedChildren : <CheckOutlined />
          }
          defaultChecked={props.defaultChecked || false}
          disabled={props.disabled || false}
          onChange={onChange}
          unCheckedChildren={
            props?.unCheckedChildren ? (
              props.unCheckedChildren
            ) : (
              <CloseOutlined />
            )
          }
        />
      </Form.Item>
    </div>
  )
}

export default FormSwitch
